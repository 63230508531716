import React from 'react'

import { Hero } from '../../components/blog/hero'
import { PostSidebar } from '../../components/blog/post-sidebar'
import { Breadcrumbs, BreadcrumbsItem } from '../../components/breadcrumbs'
import { Container } from '../../components/container'
import SEO from '../../components/seo'
import img1 from './images/shutterstock_1049157986.jpg'
import img42 from './images/shutterstock_1835733853-min.jpg'
import styles from './post-grid.module.css'

const HowToLoseWeightFast = () => {
  return (
    <div className={styles.wrapper}>
      <SEO
        title={'Chcete zhubnout rychle? Nedělejte to'}
        description={
          'Honem rychle se snažíte zhubnout před dovolenou, svatbou kamarádky nebo jinou důležitou událostí a pouštítě se kvůli tomu do extrémních diet? Není to úplně dobrý nápad. Rychlé hubnutí pomocí drastických kroků může mít spoustu negativních dopadů. My si dnes povíme, jaké to jsou.'
        }
      />
      <Container>
        <Breadcrumbs style={{ margin: '24px 0' }}>
          <BreadcrumbsItem link="/">Domu</BreadcrumbsItem>
          <BreadcrumbsItem link="/blog/posts">Blog</BreadcrumbsItem>
          <BreadcrumbsItem link="/blog/how-to-lose-weight-fast">
            Chcete zhubnout rychle? Nedělejte to
          </BreadcrumbsItem>
        </Breadcrumbs>
      </Container>
      <Container>
        <div className={styles.postGrid}>
          <div>
            <Hero
              title="Chcete zhubnout rychle? Nedělejte to"
              date="09.06.2023"
            />
            <div>
              <p className={styles.postText}>
                <b>Honem rychle</b> se snažíte <b>zhubnout</b> před dovolenou,
                svatbou kamarádky nebo jinou důležitou událostí a pouštítě se
                kvůli tomu do <b>extrémních diet</b>?{' '}
                <b>Není to úplně dobrý nápad</b>. Rychlé hubnutí pomocí
                drastických kroků může mít spoustu <b>negativních dopadů</b>. My
                si dnes povíme, jaké to jsou.
              </p>

              <img
                src={img42}
                className={styles.postImg}
                style={{ marginBottom: '40px' }}
                alt="jídlo v krabicích"
              />
              <p className={styles.postText}>Proč je rychlé hubnutí špatné?</p>

              <p className={styles.postText}>
                Rychlý úbytek na váze pomocí{' '}
                <b>extrémních diet či drastického cvičení</b> není dobrým
                řešením hned z několika důvodů. Prvním je to, že při takovém
                hubnutí ztrácíte kromě tuku hlavně <b>vodu a svaly</b>. Svaly
                jsou metabolicky aktivní tkání, která je pro nás velmi cenná
                hned z několika důvodů a rozhodně o ni{' '}
                <b>nechcete zbytečně přicházet</b>. Dalším důvodem je to, že
                takový životní styl <b> není udržitelný</b> a když se vrátíte k
                normálnímu stravování, kila zase přiberete zpátky. Dojde k
                takzvanému{' '}
                <a href="https://nutritionpro.cz/blog/co-je-to-jojo-efekt-a-jak-s-nim-zatocit/" target="_blank">
                  jojo efektu
                </a>
                . Špatně nastavený jídelníček a nedostatek živin může mít{' '}
                <b> negativní dopad na vaše zdraví</b>, ať už se bavíme o
                kvalitě vlasů, nehtů, pokožky kostí a zubů, nebo o správné{' '}
                funkci všech orgánů a imunitního systému. Takovýhle přístup{' '}
                <b> rozhodně není zdravý</b> a může vám pořádně rozházet jak
                metabolismus, tak i vaši <b> psychiku</b>, kterou můžete
                následně dávat pěkně dlouho zase dokupy. Může se také stát, že
                nebudete mít <b> dostatek energie</b> pro běžné každodenní
                aktivity. To může vést k <b> únavě</b>, podrážděnosti a{' '}
                <b> snížené produktivitě</b>.{' '}
              </p>
              <p className={styles.postText}>Co udělat místo diet? </p>
              <p className={styles.postText}>
                Zdravé hubnutí vyžaduje trpělivost a konzistentní snahu. Není
                dobré hledat zkratky v podobě všemožných extrémů a pod přísliby
                okamžitého hubnutí zakoupit kdejaké programy s různými doplňky.
                Nic z toho vám trvale nepomůže a naopak může uškodit. Zaměřte se
                proto na postupné vylepšení svého jídelníčku a sledujte, co vám
                vyhovuje. Zjistěte si, kolik kalorií a živin denně potřebujete a
                snažte se jíst vyvážená jídla. Dbejte na to, abyste měli
                dostatek zeleniny, ovoce, celozrnných výrobků, bílkovin a
                především rostlinných tuků. Jídlo si snažte naplánovat dopředu a
                stanovte si reálné cíle. Pokud nevíte, jak na to, zkuste
                například{' '}
                <a href="https://nutritionpro.cz/" target="_blank">
                  <b>krabičkovou dietu</b>
                </a>
                .
              </p>
              <p className={styles.postText}>
                Také byste neměli zapomenout na pravidelný pohyb, který může při
                hubnutí zásadně pomoci. Snažte se pravidelně cvičit, ale také co
                nejvíce hýbat v každodenním životě. Pokud sedíte většinu dne,
                zkuste se pravidelně v průběhu dne pohybovat, třeba se projít,
                protahovat nebo zvedat nějaká závaží. Začněte pomalu a intenzitu
                fyzické aktivity postupně navyšujte. Nezapomínejte ani na
                spánek. Nedostatek spánku může mít negativní vliv na hladinu
                hormonů, které ovlivňují chuť k jídlu. Spánek je také důležitý
                pro regeneraci a obnovu organismu. Myslet byste měli i na pitný
                režim, který by měla tvořit ideálně čistá voda či jiné neslazené
                nápoje.
              </p>
              <p className={styles.postText}>
                <b>Závěr</b>
              </p>
              <p className={styles.postText}>
                Mnoho lidí snaží dosáhnout rychlého hubnutí a bohužel k tomu
                mnohdy volí drastické diety či extrémní cvičení. Bohužel, touto
                cestou určitě zdravého hubnutí nedocílíte a může to mít
                negativní dopad na vaše zdraví. Pokud chcete trvale zhubnout,
                existují i lepší cesty, jako jsou třeba{' '}
                <a href="https://nutritionpro.cz/" target="_blank">
                  naše krabičky
                </a>
                . Stačí zvolit hubnoucí program a vyvážené porce plné výživného
                jídla jsou na cestě. Zapomeňte na zkratky a osvojte si raději
                základy zdravého stravování s{' '}
                <a href="https://nutritionpro.cz/" target="_blank">
                  Nutritionpro krabičkami
                </a>
                .
              </p>
              <p>Autorka: Ing. Kristina Heřmanová</p>
            </div>
          </div>
          <PostSidebar />
        </div>
      </Container>
    </div>
  )
}

export default HowToLoseWeightFast
